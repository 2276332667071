import * as React from "react";
import { RecommendationModuleProps } from "./typings";
import { RecommendationsModule as Recommendations } from "@shared-ui/retail-recommendation-module";
import { EGDSHeadingProps } from "@egds/react-core/text";
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { abbreviatedPageId } from "src/components/utility/PageIdUtil";
import { getTitle, getLocationDetails, getSearchCriteria } from "./RecommendationModuleUtils";
import { getStarRating } from "src/components/utility/ContextUtil";
import { AuthenticationState } from "bernie-context";
import { checkForBots } from "src/components/utility/BotsUtils";
import { LoyaltySignup } from "../../shared/LoyaltySignup/LoyaltySignup";
import { getAdditionalUismicromessages } from "utils/get-additional-uismicromessages";
import { withStores } from "stores";

export const RecommendationModule: React.FC<RecommendationModuleProps> = ({
  templateComponent,
  context,
  compositionStore,
}) => {
  const {
    cardContentSize,
    inputContext,
    offeringType,
    strategy,
    entity,
    placementId,
    priceStrategy,
    containerType,
    title,
    configurationIdentifier,
    showLoyaltySignUp = false,
    skipSsr = false,
    addSpacingToTitle = false,
  } = templateComponent.config;

  const mobileTitleProps: EGDSHeadingProps =
    abbreviatedPageId(context) === "HP" ? { size: 5, tag: "h3" } : { size: 5, tag: "h2" };

  const similarDestinationMobileTitleProps: EGDSHeadingProps =
    abbreviatedPageId(context) === "HP" ? { size: 4, tag: "h3" } : { size: 4, tag: "h2" };

  const titleProps: EGDSHeadingProps =
    abbreviatedPageId(context) === "HP" ? { size: 4, tag: "h3" } : { size: 4, tag: "h2" };

  const {
    metadata: { id },
  } = templateComponent;

  const customTitle = getTitle(title);
  const formattedStarRatingCode = getStarRating(context);
  const templateConfigurationIdentifier = configurationIdentifier ? configurationIdentifier : null;

  const isBot = checkForBots(context);
  const locationDetails = getLocationDetails(context?.searchContext?.location, strategy);
  const searchCriteria = getSearchCriteria(strategy, context);

  const data = {
    contentSize: cardContentSize,
    offeringType,
    strategy,
    input: inputContext,
    recommendationContext: {
      outputLineOfBusiness: entity,
      lodging: {
        propertyId: context.searchContext.location?.id ? String(context.searchContext.location?.id) : null,
        discoveryFlexFilters: {
          starRatingCode: context.searchContext.starRating?.code ? String(formattedStarRatingCode) : null,
          accommodationTypeCode: context.searchContext.alternativeAccommodation?.code
            ? String(context.searchContext.alternativeAccommodation?.code)
            : null,
          affinityTypeCode: context.searchContext.affinity?.code ? String(context.searchContext.affinity?.code) : null,
          hotelBrandCode: context.searchContext.hotelBrand?.code
            ? String(context.searchContext.hotelBrand?.code)
            : null,
        },
        ...searchCriteria,
      },
      ...(context.searchContext.location && {
        destination: {
          ...((locationDetails?.id || locationDetails?.type) && {
            placeId: {
              id: locationDetails.id,
              type: locationDetails.type,
            },
          }),
          ...(!Number.isNaN(Number(context.searchContext.location.parent?.geoCoordinate?.latitude)) &&
            !Number.isNaN(Number(context.searchContext.location.parent?.geoCoordinate?.longitude)) && {
              placeCoordinates: {
                latitude: Number(context.searchContext.location.parent.geoCoordinate.latitude),
                longitude: Number(context.searchContext.location.parent.geoCoordinate.longitude),
              },
            }),
        },
      }),
      pageId: abbreviatedPageId(context),
    },
    placementId,
    priceStrategy,
    containerType,
    configurationIdentifier: templateConfigurationIdentifier,
  };

  const injectedComponents = [
    {
      index: 3,
      component: <LoyaltySignup context={context} />,
    },
  ];

  const additionalMicromessages = getAdditionalUismicromessages(context, compositionStore);

  const isAuth = context.user?.authState === AuthenticationState.AUTHENTICATED;

  if (!isAuth && entity === "ACTIVITIES") return null; // identity check for TnL 51630

  return (
    <Viewport>
      <ViewSmall>
        <div id={id}>
          <Recommendations
            inputs={data}
            titleProps={addSpacingToTitle ? similarDestinationMobileTitleProps : mobileTitleProps}
            customTitle={customTitle}
            skipSsr={isBot ? false : skipSsr}
            injectedComponents={showLoyaltySignUp ? injectedComponents : undefined}
            isSimilarPropertiesCarousel={addSpacingToTitle}
            additionalUisMicroMessages={additionalMicromessages}
          />
        </div>
      </ViewSmall>
      <ViewMedium>
        <div id={id}>
          <Recommendations
            inputs={data}
            titleProps={titleProps}
            customTitle={customTitle}
            skipSsr={isBot ? false : skipSsr}
            injectedComponents={showLoyaltySignUp ? injectedComponents : undefined}
            isSimilarPropertiesCarousel={addSpacingToTitle}
            additionalUisMicroMessages={additionalMicromessages}
          />
        </div>
      </ViewMedium>
    </Viewport>
  );
};

export default withStores("context", "compositionStore")(RecommendationModule);
